import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/layout";

export default () => {

  return (
    <Layout>

    <div style={{ 'position': 'relative' }}>
      <Link to='/oer-band-impulse-heft-3-pattern-und-styles-1' className="closeX"><span>X</span></Link>
      <h2>Mixing-Projekt zum <i>Rap-Pattern</i></h2>
    </div>
    <div style={{ 'marginTop': '20px' }}>
      <p>
        Lernen Sie die Stimmen des Rap-Patterns kennen, indem Sie die Lautstärke an den einzelnen Tracks verändern. Wenn Sie sich Einzelspuren angehört haben und die Tracks nicht mehr synchron gespielt werden, verwenden Sie die Schaltflächen 'Reset' und 'Start / Stopp' zum Synchronisieren. Die Lautstärke lässt sich an den Audioplayern für jeden Kanal einzeln regeln und mit dem Slider für alle Spuren gleichzeitig. Achten Sie darauf, dass die Sounddateien geladen sind, bevor es losgeht. Viel Spaß beim Mixen des Rap-Patterns...
      </p>
      <div><Link to="/oer-band-impulse-heft-3-pattern-und-styles-1"><img src="/Images/kaiser_openBookBandImpulse-3.jpg" alt="" /></Link></div>
    </div>
    <div style={{ 'marginTop': '40px', 'display': 'flex' }}>
      <button style={{ 'marginRight': '10px' }} id="startStoppButton" onClick={() => onStartStoppClick()}>Start / Stopp</button>       
      <button style={{ 'marginRight': '10px' }} id="resetButton" onClick={() => onResetClick()}>Reset</button>       
      <input style={{ 'marginTop': '4px' }} id="volumeButton" onChange={() => onVolumeChange()} type="range" step="1" />
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/PatternStyles1/Gitarre-1.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Gitarre 1 (Lead)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/PatternStyles1/Gitarre-2.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Gitarre 2 (Akkorde Am/Em)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/PatternStyles1/Bass.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">E-Bass</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/PatternStyles1/Keyboard-1.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Keyboard 1</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/PatternStyles1/Keyboard-2.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Keyboard 2</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/PatternStyles1/Klatschen.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Handclap</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/PatternStyles1/Cajon.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Cajón</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/PatternStyles1/Kick.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Kick (Bassdrum)</p>
      </div>
    </div>
    <Helmet>
      <title>OER | Ulrich Kaiser - Mixing Projekt</title>
      <meta name="description" content="Hier finden Sie ein Mixing-Projekt zum Studieren des Arrangements sowie der Einzelstimmen zum einem OpenBook (OER) von Ulrich Kaiser für die Bandklasse." />
      <meta name="keywords" content="Projekt, Bandklasse, OER, Open Educational Resources, OpenBooks" />
      <meta name="copyright" content="Ulrich Kaiser, 2011" />
    </Helmet>
  </Layout>
  )
}

